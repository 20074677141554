#success-stories {
    background: linear-gradient(90deg, #C308AD 0%, #0B147D 100%);
    margin-top: -30px;
}

.success-stories .title{
    margin-top: 30px !important;
    padding-top: 30px;
    margin-bottom: 30px;
}
.success-stories .title h2{
    color: rgba(255, 255, 255, 1);
}

.success-stories .success-div-data{
   margin-top: 1rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.success-stories .success-text-data .icon{
    font-size: 2.5em;
    color:  rgba(255, 255, 255, 0.5);
    margin-right: 20px;
}

.success-text-data-1{
    margin-top: -30px;
    margin-left: 70px !important;
}

.success-stories .success-div-data img{
    width: 330px;
    height: 380px;
    top: 2465px;
    left: 149px;
    gap: 0px;
    border-radius: 20px;
    margin-bottom: 20px;
    object-fit: cover;
}

.success-stories .success-div-data h6{
    color: rgba(255, 255, 255, 1);
    font-family: var(--font-one);
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
    text-align: center;

}
.success-stories .success-div-data p{
    color: rgba(255, 255, 255, 1);
    font-family: var(--font-one);
    font-size: 16px;
    font-weight: 500;
    line-height: 21.79px;
    text-align: center;

}
.success-stories .success-text-data{
    padding: 20px;
}

.success-stories .success-text-data p{
    color: rgba(255, 255, 255, 1);
    font-family: var(--font-one);
    font-size: 20px;
    font-weight: 400;
    line-height: 27.24px;
    text-align: left;

}
.loading-success{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.loading-success .progress {
    max-width: 100%;
    width: 400px;
    height: 6px;
    background-color: white;
}
.loading-success .progress .progress-bar{
    max-width: 100%;
    width: 305px;
    height: 6px;
    background-color: rgba(207, 70, 192, 1) !important;
}
.loading-success .button{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid rgba(204, 204, 204, 1);
}
.loading-success .button .icon{
  font-size: 24px;
}

@media (max-width: 576px){
    .loading-success{
        flex-direction: column;
    }
    .loading-success .progress{
        margin-bottom: 50px;
    }
    
}
