
.mywishlist{
    padding: 20px;
    /* background: #f5f5f5; */
    margin-top: 5rem;
}

.wishlist-banner-card{
    margin-bottom: 20px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    background: radial-gradient(43.47% 113.8% at 13.66% 39.15%, #5575A4 2.28%, #304461 100%);
    color: white;
    border: 0;
    max-height: 100%;
    /* height: 355px; */
    margin: 30px;
}

.wishlist-banner .banner-image{
    width: 324px;
    height: 318px;
    object-fit: cover;
}
.wishlist-banner .banner-image img{
    width: 324px;
    height: 318px;
    object-fit: contain;
}
.wishlist-banner-row{
    padding: 20px;
}
.banner-content-sub{
    margin-top: 10%;
}
.banner-content-sub .title-banner{
    font-family: var(--font-one);
    font-size: 30px;
    font-weight: 600;
    line-height: 49px;
    text-align: center;
}

.wishlist-job-list{
    padding: 80px;
    padding-top: 10px;
}
.wishlist-job-main-div .card{
    box-shadow: 0px 1px 4px 0px #00000040;
    border: 0;
    border-radius: 15px;
    margin-bottom: 20px;
}
.wishlist-job-main-div .card .card-header{
    display: flex;
    justify-content: space-between;
    border: 0;
    padding-bottom: 0;
    background: white;
}
.wishlist-job-main-div .card .card-header .wishlist-job-image{
    width: 160px;
    height: 50px;
    object-fit: cover;
}
.wishlist-job-main-div .card .card-header .wishlist-job-image img{
    width: 160px;
    height: 50px;
    object-fit: cover;
}

.wishlist-job-main-div .card .card-header .button-trash{
    color: var(--main-footer);
    font-size: 2rem;
}

.wishlist-job-main-div .card .card-body1 .description{
    padding: 20px;
    padding-bottom: 0;
    padding-top: 0;
}
.wishlist-job-main-div .card .card-body1 .description-sub{
    padding: 20px;
    padding-top: 0;
}
.wishlist-job-main-div .card .card-body1 .description .comname{
    font-family: var(--font-one);
    font-size: 18px;
    font-weight: 600;
    line-height: 24.51px;
    color: #1E1E1E;

}

.wishlist-job-main-div .card .card-body1 .description .comtitle{
    font-family: var(--font-one);
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    color: #5A5959;
}

.loc-skill p{
    margin-bottom: 0 ;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    color: #7F7F7F;
}
.loc-skill .location-wish-main{
    display: flex;
    justify-content: flex-start;
}
.loc-skill .skills-wish-main{
    display: flex;
    justify-content: flex-start;
}
.skills-wish-main .skills-wish .skill-wish-sub{
    font-weight: 600;
}
.skills-wish-main .skills-wish .skill-wish-sub-text{
   text-wrap: wrap;
}

/* .wishlist-job-main-div .card .card-body1 .description-sub{
    display: flex;
    justify-content: space-between;
    align-items: center;
} */
.wishlist-job-main-div .card .card-body1 .description-sub .but-div{
    display: flex;
    justify-content: flex-end;
}
.wishlist-job-main-div .card .card-body1 .description-sub .but-div .btn{
    border: 0;
    border-radius: 20px;
}
.wishlist-job-main-div .card .card-body1 .description-sub .but-div .btn1{
    /* padding: 8px 20px; */
    margin-right: 10px;
    color: var(--main-footer);
    border: 1px solid var(--main-footer);
    background: white;
    font-family: var(--font-one);
    font-size: 16px;
    font-weight: 600;
}
.wishlist-job-main-div .card .card-body1 .description-sub .but-div .btn2{
    /* padding: 8px 20px; */
    margin-right: 10px;
    color: white;
    background: var(--main-footer);
    border: 1px solid var(--main-footer);
    font-family: var(--font-one);
    font-size: 16px;
    font-weight: 600;
}

@media (max-width:768px) {
    .wishlist-job-list{
        padding: 20px;
    }
    .wishlist-job-main-div .card .card-body1 .description-sub .but-div{
        justify-content: flex-start;
        margin-top: 10px;
    }
}
@media (max-width:465px) {
    .wishlist-banner .banner-image{
        width: 200px;
        height: 220px;
        object-fit: cover;
    }
    .wishlist-banner .banner-image img{
        width: 200px;
        height: 220px;
        object-fit: cover;
    }
    .banner-content-sub .title-banner{
        font-size: 20px;
        line-height: 30px;
    }
}