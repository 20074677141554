.course-overview .title{
    padding-top: 50px;
    padding-bottom: 50px;
}

.course-overview .sub-course{
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
    text-align: left;
    color: rgba(96, 96, 96, 1);
    flex-wrap: wrap;
}

.course-overview .text-course-row{
    padding: 35px;
}

.course-overview .text-course-img{
   display: flex;
   justify-content: center;
   align-items: center;
}
.course-overview .text-course-img img{
    width: 500px;
}
.course-overview .card-div-purple{
    margin-top: 5rem;
}

@media (max-width: 950px) {
    .course-overview .text-course-img img{
        width: 450px;
    }
}

@media (max-width: 769px){
    .course-overview .text-course-img img{
        width: 400px;
    }
}


@media (max-width: 405px){
    .course-overview .text-course-img img{
        width: 300px;
    }
}


.course-overview .card1{
    max-width: 100%;
    width: 839px;
    height: 140px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    border: 0;
    display: flex;
}

@media (max-width: 985px) {
    .course-overview .card1{
        height:250px ;
    }
    .course-overview .card1 .purple{
        margin-bottom: 10px;
    }
}



.course-overview .card1 .purple{
    max-width: 100%;
    width: 331px;
    height: 140px;
    border-radius: 20px 0px 0px 20px;
    background-image: url(../../../assets/img/courseoverview/coursebg.png);
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.course-overview .card1 .purple h5{
    font-family: Open Sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 43.58px;
    color: rgba(30, 30, 30, 1);
}

.course-overview .card1 .purple2{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    /* padding-top: 28px; */
}

.course-overview .card1 .purple2 h5{
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.24px;
    color: var(--main-blue);

}

.course-overview .card1 .purple2 img{
    width: 82px;
    height: 82px;
    border-radius: 50%;
}

.course-overview .skill-card-overview{
    margin-top: 5rem;
}
.course-overview .skill-card-overview .card2{
    max-width: 100%;
    width: 1014px;
    height: 300px;
    border-radius: 30px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);
    border: 0;
    padding-top: 40px;
}

@media (max-width: 446px){
    .course-overview .skill-card-overview .card2{
        padding: 20px;
    }
    .course-overview .skill-card-overview .card2 .skill-item h6{
        font-size: 12px;
    }
    .course-overview .skill-card-overview .card2 .skill-item .icon{
        margin-right: 5px;
        font-size: 1em;
    }
}

@media (max-width: 385px){
    .course-overview .skill-card-overview .card2{
        padding: 0px;
    }
    .course-overview .skill-card-overview .card2 .skill-item .icon{
        margin-right: 0px;
        font-size: 0.5em;
    }
    .course-overview .skill-card-overview .card2 .skill-item h6{
        font-size: 10px;
    }
}

.course-overview .skill-card-overview .card2 h5{
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 700;   
    line-height: 32.68px;
    text-align: center;
}
.course-overview .skill-card-overview .card2 .skill-row{
    padding-top: 40px;
    margin: 10px;
}
.course-overview .skill-card-overview .card2 .skill-col{
    display: flex;
    justify-content: center;
}
.course-overview .skill-card-overview .card2 .skill-item {
    background: rgba(228, 229, 255, 1);
    color: rgba(96, 96, 96, 1);
    border-radius: 50px !important;
    max-width: 100%;
    /* width: 180px; */
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .course-overview .skill-card-overview .card2 .skill-item .icon{
    margin-right: 5px;
    font-size: 1.5em;
    margin-top: -10px;
  }
  .course-overview .skill-card-overview .card2 .skill-item h6{
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.24px;
    color: rgba(96, 96, 96, 1);
  }

  @media (max-width: 990px) {
    .course-overview .skill-card-overview .card2{
        height: 380px;
    }
  }
  @media (max-width: 768px) {
    .course-overview .skill-card-overview .card2{
        height: 800px;
    }
  }