.student-profile-sec{
    margin-top: 3rem;
    padding: 40px;
}
.profile-sub-img img{
    width: 100px;
    height: 100px;
    object-fit: cover;
}


@media  (max-width: 890px){
    .student-profile-sec{
        padding: 0 !important;
    }
}
.student-profile-sec .card{
    border-radius: 12px !important;
    border: 0;
    box-shadow: 0px 2px 4px 0px #00000040;
}

@media (max-width:1025px){
    .card-text1 {
        font-size: 12px !important;
    }
}
 @media (max-width:767px) {
    .profile-sub{
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }
    .student-profile-sec{
        margin-top: 4rem;
    }
    .profile-sub-info .card-title{
        text-align: left;
        margin-left: 10px !important;
    }
    .profile-sub-info{
        padding: 20px !important;
        margin-top: 10px;
    }
 }

 @media (max-width:430px){ 
    .profile-sub-info{
        padding: 10px !important;
    }
   .student-profile-sec .card .card-body .card-title{
    font-size: 18px;
    }
    .card-text1 {
        font-size: 12px;
    }
    .profile-sub-img img{
        width: 60px;
        height: 60px;
        object-fit: cover;
    }
 }

 @media (max-width:374px){
    .card-text1 {
        font-size: 10.5px !important;
    }
 }

 .student-details-icon{
    color: #524C71;
    cursor: pointer;
    font-size: 1.1rem;
 }

.student-profile-sec .card .card-body{
    padding-top: 0 !important;
}
.card-pro-details-title{
    display: flex;
    justify-content: space-between;
}
.student-profile-sec .card-pro-details .card-body{
    padding: 20px !important;
}

.student-profile-sec .card-pro-details h5{
    font-family: var(--font-one);
    font-size: 20px;
    font-weight: 600;
    line-height: 27.24px;
    text-align: left;
    color: #000000;

}
.student-profile-sec .card-pro-details p{
    font-family: var(--font-one);
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
    color: #272727;
    margin-bottom: 0;
}
.certificate-sub-div{
    margin-bottom: 15px;
}
.card-text1 {
    font-family: var(--font-one);
    font-weight: 400;
    font-size: 14px;
    color: #F0F0F0;
    list-style: 19.7px;
    display: flex;
    margin: 5px;
    margin-left: 20px;
}
.student-profile-card h5{
    color: #F0F0F0;
    font-weight: 600;
    font-size: 20px;
    font-family: var(--font-one);
}
.student-profile-card{
    background: #455A64 !important
}
.student-profile-sec .card .card-body .card-title{
    font-family: var(--font-one);
    font-size: 20px;
    font-weight: 600;
    line-height: 27.24px;
}
.personal-details-row{
    margin-left: 2px;
    margin-bottom: 10px;
}
  
.profile-address{
    width: 450px;
    max-width: 100%;
}
.personal-sub-details{
    font-family: var(--font-one);
    font-size: 18px !important;
    font-weight: 600;
    margin-bottom: 0;
    color: #000000;
}
.personal-edu-sect{
    font-size: 12px;
}
.sub-mini-title{
    font-family: var(--font-one);
    font-size: 16px;
    font-weight: 400;
}

.card-unorder-list li{
  list-style: none;
}
.card-unorder-list{
    padding: 0px;
    margin-top: 10px;
}

.form-modal{
    border-radius: 5px !important;
}
.student-profile-btn1{
    background: var(--main-footer) !important;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease; 
}
.student-profile-btn1:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}
.student-profile-btn2{
    background: var(--secondary-two);
    color: var(--main-blue) !important;
    border: 0 !important;
    border-radius: 0 !important;
}
.upl-text{
    font-weight: 600;
    color: #7F88A7;
}

@media (max-width:769px) {
    .quick-links-main{
        display: none;
    }    
}
.quick-links-main .list-group-item.active{
    background: var(--main-footer);
    color: #ffff;
    border: 0;
    border-radius: 5px;
}
.quick-links-main li{
    cursor: pointer;
    margin-bottom: 1px;
}
/* .quick-links-main .list-group-item{
    padding: 0 !important;
    padding-left: 4px !important;
} */
.quick-links-main .links{
    text-decoration: none;
    font-family: var(--font-one);
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
    text-align: left;
}
.quick-links-main li:hover{
    background: var(--main-footer);
    color: #ffff;
    border-radius: 5px;
}

/* Certification */
.certi-name{
    font-family: var(--font-one);
    font-size: 18px !important;
    color: #000000;
    font-weight: 600;
    margin-bottom: 0;
}
.certi-sub-name{
    font-family: var(--font-one);
    font-size: 16px !important;
    color: grey !important;
    font-weight: 600 !important;
}
.certi-date{
    font-family: var(--font-one);
    font-size: 15px !important;
    color: grey !important;
    font-weight: 500 !important;
}
.certi-link{
    font-weight: 500 !important;
}
.socl-desc{
    font-family: var(--font-one);
    font-size: 14px !important;
    font-weight: 500 !important;
    color: grey !important;
}
.skill-list-text{
    font-family: var(--font-one);
    font-size: 16px !important;
    color: #000000 !important;
    font-weight: 600 !important;
}

.selected-skills {
    display: flex;
    flex-wrap: wrap; 
    gap: 10px; 
  }
  
  .selected-skill {
    background-color: #f0f0f0;
    /* padding: 5px 10px; */
    border-radius: 5px;
    display: flex;
    align-items: center; 
  }

.selected-skill-group{
    background: var(--main-footer);
    color: #F0F0F0;
    padding-left: 3px;
    border-radius: 5px;
    margin-bottom: 2px;
}

.selected-skill-group .btn-skill-del{
    margin-top: -5px;
    color: #F0F0F0;
}


/* Modals */
.modal-content{
    border-radius: 15px !important;
    border: 0 !important;
    padding: 10px;
}
.modal-header{
    border-bottom: 0px !important;
}
.modal-content .modal-body .form-control{
    border-radius: 10px !important;
}
.modal-content .modal-body .form-label{
    font-weight: 500 !important;
    color: #000000;
}
.opt{
    font-weight: 200 !important; 
    font-size: 16px !important;
}

.square-doc-img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}
.editEdu-filediv-icon{
    display: flex;
    justify-content: space-between;
}
.editEdu-filediv-icon .icn{
    color: var(--main-blue);
}
.custom-modal-header{
    flex-direction: column !important;
    align-items: flex-start !important;
}

.profile-upload-sect{
    margin-top: 20px;
}
.upload-btn-edu{
    height: 136px;
    border: 1px solid #7F88A7;
    border-radius: 25px;
    text-align: center;
    align-items: center;
    padding-top: 30px;
}

.upload-btn-edu .upload-btn-file{
    width: 200px;
    height: 40px;
    top: 990px;
    left: 321px;
    gap: 0px;
    border-radius: 40px;
    border: 1px 0px 0px 0px;
    border: 1px solid var(--main-blue);
    color: var(--main-blue); 
}
.upload-btn-edu .upload-btn-file:hover{
    border: 1px solid var(--main-blue);
    color: var(--main-blue); 
}

.img-profile-upload{
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
}

.custom-btn-upload{
    text-align: center !important;
    margin-top: 20px;
}
.docul-name{
    color: var(--main-footer);
}
