#featured-internship{
    margin-bottom: 15rem;
}

#featured-internship .title{
   margin-bottom: 5rem;
}

@media (max-width: 768px) {
    #featured-internship{
        margin-bottom: 0 !important;
    }
}