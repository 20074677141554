.company-card-marquee{
    /* position: absolute; */
    margin-top: -60px;
}
.company-card-marquee{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.company-card-marquee .card{
  max-width: 100%;
  width: 1088px;
  height: 180px;
  border-radius: 40px;
  padding-top: 35px;
}
@media (max-width:426px) {
  .company-card-marquee .card{
   border: 0;
  }
}
.company-card-marquee .card img{
  width: 120px;
  height: 120px;
  object-fit: contain;
  aspect-ratio: 5/3;
  margin-top: -40px;
}

.company-card-marquee p{
    font-family: var(--font-one);
    font-size: 20px;
    font-weight: 500;
    line-height: 27.24px;
    text-align: center;

}