.joblist{
    margin-top: 8rem;
}

.custom-dropdown-toggle{
    background-color: var(--main-footer) !important;
    border: var(--main-footer) !important;
    margin: 0px 10px;
    display: flex;
    justify-content: space-between !important;
    flex-wrap: wrap;
    margin-bottom: 5px;
    width: 220px;
}

.custom-dropdown-item:hover{
    background-color: var(--main-footer) !important;
    color: white !important; 
}
.custom-dropdown-item.active {
    background-color: var(--main-footer) !important;
    color: white !important; 
  }

.custom-dropdown-menu{
    margin-top: 5px !important;
    max-height: 200px;
    overflow-y: auto;
    padding: 0px !important;
}

.custom-dropdown-menu .fixed-search {
    position: sticky;
    top: 0;
    background: white; 
    z-index: 1;
}
.no-border-focus:focus {
    border-color: transparent !important;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08) !important;
    border: 1px solid var(--main-footer) !important;
  }
.dropdown-text{
    font-size: 1.2rem;
    color: var(--main-text);
    margin: 0px 10px;
    margin-right: 20px;
}
@media (max-width: 374px) {
    .custom-dropdown-toggle{
        margin-bottom: 10px;
    }
    .filter-main-div{
        margin: 0px !important;
        margin-top: 0px;
     }   
}
.joblist .filter-icon{
    color: var(--main-footer);
    font-size: 22px;
}
.joblist .filter-text{
    color: var(--main-footer);
    font-size: 22px;
    font-family: var(--font-one);
    font-weight: 700;

}
.filter-main-div{
   margin: 50px;
   margin-top: 0px;
}
.joblist .filter-card{
    margin-top: 10px;
    padding: 30px;
    border: 0.5px solid #E0E0E0;
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    width: 100%;
}

.joblist .filter-dropdown-div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

/* Pagination */
  .pagination-listing {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .pagination-listing button {
    background-color: transparent;
    border: 1px solid var(--main-footer);
    border-radius: 50%;
    color: var(--main-footer);
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pagination-listing button:hover {
    background-color: var(--main-footer);
    color: #fff;
  }
  
  .pagination-listing button.active {
    background-color: var(--main-footer);
    color: #fff;
    border-color: var(--main-footer);
    border-radius: 50%;
  }