@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

#AboutHero {
  margin-top: 5rem;
  position: relative;
  background: linear-gradient(
      #ffffff 0%,
      #ffffff 28.1%,
      rgba(255, 255, 255, 0) 100%
    ),
    url("./img/Rectangle 106.png") !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}
#AboutHero .hdl-ac {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 54px;
  line-height: 73.54px;
  background: linear-gradient(to right, #c308ad 10%, #0b147d 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}
@media (max-width: 426px) {
  #AboutHero .hdl-ac {
    font-size: 30px !important;
    line-height: 35px !important;
  }
}
#AboutHero .sub-ac {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32.68px;
  color: #303030;
}

#AboutHero .form-headline {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 27.24px;
  color: #5b5b5b;
}
.about-hero {
  display: flex;
  flex-direction: row;
  padding: 0; /* Ensure no padding */
  margin: 0; /* Ensure no margin */
}

.left-section,
.right-section {
  padding: 0; /* Ensure no padding inside columns */
  margin: 0; /* Ensure no margin inside columns */
}

.row.no-gutters {
  margin: 0; /* Ensure no margin for row */
}

.col-sm-12,
.col-md-6 {
  padding: 0; /* Ensure no padding for columns */
}

.form-container {
  background-color: #ffffff; /* White background for the form container */
  padding: 20px; /* Adjust padding as needed */
  border-radius: 30px; /* Optional: add rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 10px solid #00a2b8; /* Optional: add shadow for better visual separation */
  max-width: 516px;
  margin-left: 100px !important;
}

.input-group-border {
  border-radius: 30px;
  padding-top: 6px;
  padding-bottom: 6px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15); /* Drop shadow */
}

.no-border {
  border: none; /* Remove border from the input fields */
}

.about-hero .input-group-text {
  background-color: transparent; /* Ensure background is transparent */
  border: none; /* Remove border from the input-group-text */
}

.about-hero .icon-img {
  width: 20px; /* Adjust icon size */
  height: 20px; /* Adjust icon size */
  margin-left: 10px; /* Adjust spacing between icon and text */
}

.full-width-btn {
  width: "100%";
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 27.24px;
  cursor: pointer !important;
}
.input-group-border {
  border: 1px solid #ccc; /* Add border to the input group div */
  border-radius: 30px; /* Rounded corners */
  padding-top: 4px; /* Padding at the top */
  padding-bottom: 4px; /* Padding at the bottom */
}

.input-group-text {
  background-color: transparent; /* Ensure background is transparent */
  border: none; /* Remove border if you don't want it */
}

.about-hero .icon-img {
  width: 20px; /* Adjust icon size */
  height: 20px; /* Adjust icon size */
  margin-left: 10px; 
}


.full-width-btn {
  width: 100%; 
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.about-hero .btn-connect {
  background-color: var(--main-blue); 
  border-color: var(--main-blue); 
  color: #fff; 
  padding: 10px; 
  margin-top: 50px;
  border-radius: 30px;
}
.about-hero .btn-connect:hover{
  border: 1px solid var(--main-blue);
  color: var(--main-blue);
}

.about-hero .icon-arrow {
  margin-left: 10px; 
  font-size: 1.2rem;
}
.about-hero .form-control{
  border-radius: 30px;
}
.about-hero .form-control.no-border {
  border: none; 
}

.form-control::placeholder {
  color: #b7b7b7;
  opacity: 1;
  font-size: 18px;
}
.about-hero .form-control:focus {
  border-color: transparent;
  box-shadow: none;
}

@media (max-width: 1200px) {
  .about-hero .hdl-ac {
    font-size: 48px;
    line-height: 64px;
  }

  .about-hero .sub-ac {
    font-size: 20px;
    line-height: 27px;
  }
}

@media (max-width: 992px) {
  .about-hero .hdl-ac {
    font-size: 42px;
    line-height: 56px;
  }

  .about-hero .sub-ac {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .about-hero .hdl-ac {
    font-size: 36px;
    line-height: 48px;
  }

  .about-hero .sub-ac {
    font-size: 16px;
    line-height: 22px;
  }
  .left-section{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media (max-width: 610px) {
  .form-container{
    margin: 20px !important;
  }
}

@media (max-width: 576px) {
  .about-hero .hdl-ac {
    font-size: 30px;
    line-height: 40px;
  }
  .form-control::placeholder {
    color: #b7b7b7;
    opacity: 1;
    font-size: 12px;
  }

  .about-hero .sub-ac {
    font-size: 14px;
    line-height: 20px;
  }
}


@media (max-width: 360px){
  .full-width-btn{
    font-size: 15px;
    
  }
}

@media (max-width: 426px){
  .about-hero .form-container{
    padding: 10px !important;
  }
  .about-hero .form-container p{
    font-size: 15px;
  }
}


/* OTP Section */

.otp-validation h5{
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 25px;
  line-height: 27.24px;
  color: var(--main-blue);
  text-align: center;
}
.otp-validation .otp-title{
  margin-bottom: 50px;
}
@media (max-width:426px) {
  .otp-validation h5{
    font-size: 15px;
    margin-top: 20px;
  }
  .otp-validation .btn-validate{
    margin-bottom: 20px;
  }
}
.otp-input-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-bottom: 50px !important;
}
.otp-validation .btn-validate{
  background: var(--main-blue);
  color: white;
}
.otp-validation .btn-validate:hover{
  border: 2px solid var(--main-blue);
  color: var(--main-blue);
  background: white;
}

.custom-select-comapany-employer{
  padding: 10px 20px;
}
