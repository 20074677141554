#let_connect {
    background: linear-gradient(
        #C308AD 0%, 
        #C308AD 28.1%, 
        #C308AD 100% 
    ),
    url("./img/plain-white-background-xogwcn03366cdja2 1.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
    position: relative;
    
}

.hdl-lc {
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 45px;
    line-height: 61.28px;
}

.left-section img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    position: absolute;
    bottom: 0px;
}

.btn-custom {
    background-color: white; 
    border-radius: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 27.24px;
    color: #C308AD;
    border: 1px solid #C308AD;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15) !important;
}

.btn-custom:hover {
    background-color: #f8f9fa;
    color: #C308AD;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 600px) {
 
    .hdl-lc{
        font-size: 1.9rem;
        line-height: 26.28px;
    }
    .btn-custom {
        font-size: 0.9rem;
        padding: 10px 30px !important;
    }
}
@media (max-width:768px) {
    .left-section img {
        position: static;
        margin-top: 20px; 
    }
}
