.Play-banner{
    /* background: linear-gradient(90deg, #C308AD 0%, #0B147D 100%),
      url("../../assets/img/playstore/play-bg.png"); */
    background: radial-gradient(53.55% 192.58% at 82.08% 50%, #304461 0%, #203F6B 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* max-height: 100%; */
    height: 100%;
    width: 100%;
    object-fit: cover;  
    margin-bottom: 2rem;
}
.Play-banner-div{
    padding-left: 47px;
}
.Play-banner .baneer-text{
    margin-top: 20px;
    padding: 40px;
}
@media (max-width: 990px) {
    .Play-banner .baneer-text{
        margin-top: 0px;
        padding-top: 0;
        padding-bottom: 5px;
    }
    .Play-banner .play-btn{
        padding-bottom: 20px;
    }
    
}
@media (max-width: 576px) {
    .Play-banner .baneer-text{
        margin-top: 30px;
    }
    
}
.Play-banner .baneer-text h1{
    font-family: var(--font-one);
    font-size: 46px;
    font-weight: 700;
    line-height: 62.64px;
    color: white;
    flex-wrap: wrap;
}

.Play-banner .baneer-text p{
    font-family: var(--font-one);
    font-size: 24px;
    font-weight: 600;
    line-height: 32.68px;
    color: #ECECEC;
    flex-wrap: wrap;
    margin-top: 25px;
}
@media (max-width:426px) {
    .Play-banner .baneer-text h1{
        font-size: 36px;
        line-height: 42.39px;
    }
    .Play-banner .baneer-text p{
        font-size: 18px;
        line-height: 24px;
    }
}
.Play-banner .play-btn{
    margin-bottom: 20px;
    margin-left: 30px;
}

.Play-banner .app-img{
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
}
.Play-banner .app-img .img1{
    position: relative;
    max-width: 100%;
    height: 604.72px;
    object-fit: cover;
    margin-top: -90px;
}

@media (max-width: 400px) {
    .Play-banner .app-img{
        margin-top: 20px;
    }
    .Play-banner .app-img .img1{
        object-fit: contain !important;
        height: 100%;
    }
    
}
