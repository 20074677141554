#benefits{
   margin-top: 5rem;
}
#benefits .benefits-1{
    margin-top: 80px;
}
#benefits .benefits-img-div{
   display: flex;
   justify-content: center;
}
#benefits .benefits-img{
   background-image: url(../../../assets/img/benefits/benfibg.png);
   background-repeat: no-repeat;
   object-fit: contain;
   width: 206px;
   height: 193px;
   display: flex;
   justify-content: center;
   align-items: center;
}

#benefits .benefits-img img{
    width: 132px;
    height: 132px;
}
#benefits .benefits-deta{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}
#benefits .benefits-deta h5{
    font-family: var(--font-one);
    font-size: 20px;
    font-weight: 600;
    line-height: 27.24px;
    color: rgba(48, 48, 48, 1);
    text-align: center;
}
#benefits .benefits-deta p{
    max-width: 100%;
    width: 384px;
    margin-top: 10px;
    font-family: var(--font-one);
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: center;
    color: rgba(96, 96, 96, 1);
    text-wrap: wrap;
}

