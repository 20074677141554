.my-application{
    margin-top: 6rem;
}

.my-application .my-application-title h2{
    font-family: var(--font-one);
    font-size: 30px;
    font-weight: 600;
    line-height: 49px;
    color: var(--main-footer);
    margin-left: 35px;
}
@media (max-width: 991px) {
    .my-application .my-application-title h2{
        margin-left: 0px;
    }
}
.my-application .card{
    /* width: 360px; */
    width: 100%;
    max-width: 100%;
    box-shadow: 0px 1px 4px 0px #00000040;
}
.my-application .card .my-card-sub-div{
   margin: 20px;
}

.my-application .card p{
    color: #7F7F7F !important;
}
.my-application .card .div-success{
    margin-left: 40px;
    margin-top: auto;
    margin-bottom: 10px;
}
.my-application .card .div-success .div-success-text{
    font-family: var(--font-one);
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    text-align: left;
    color: #858585;
}
.my-application .card .div-success .div-success-icon{
    color: #028C3E;
}

