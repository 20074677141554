#privacyPolicy {
  position: relative;
  background-image: url("../../assets/img/privacyPolicy/bgImage.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 30px;
  padding-right: 30px;
}

#privacyPolicy::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

#privacyPolicy * {
  position: relative;
  z-index: 2;
}

.section-privacyPolicy {
  background-color: #f9f9f9;
  padding: 4rem 0;
  color: #333;
}

.container-privacyPolicy {
  margin: 0 auto;
}

.title-privacyPolicy {
  text-align: center;
  padding-bottom: 2rem;
}

.title-privacyPolicy h2 {
  color: #2c3e50;
  font-size: 2.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-title {
  margin-right: 10px;
  font-size: 2rem;
  color: #2980b9;
}

.content-privacyPolicy {
  line-height: 1.8;
}

.content-privacyPolicy p,
.content-privacyPolicy ul {
  font-size: 1.2rem;
}

.link-privacyPolicy {
  color: #2980b9;
  text-decoration: none;
}

.link-privacyPolicy:hover {
  text-decoration: underline;
}

.link-contact {
  color: #27ae60;
  text-decoration: none;
}

.link-contact:hover {
  text-decoration: underline;
}

h3 {
  margin-top: 2rem;
  color: #2c3e50;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.icon-section {
  margin-right: 10px;
  font-size: 1.5rem;
  color: #2980b9;
}

.list-privacyPolicy {
  margin: 1rem 0;
  padding-left: 20px;
}

.user-deletion-policy-subhead{
  font-size: 1.2rem;
  font-weight: bold;
  color: #384858;
}

.user-deletion-policy-subdesc{
  color: blue;
  text-decoration: underline;
}
