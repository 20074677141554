@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root{
  --font-one: "Open Sans", sans-serif;
  --font-two: "Bree Serif", serif;
  /* --main-blue: #2D2F80; */
  --main-blue: #304461;
  --main-footer: #304461;
  --secondary-one: #606060;
  --secondary-two: #F2F3FF;
  --secondary-third: #7882AD1A;
  --mix-blue-color: linear-gradient(90deg, #2D2F80 39.6%, #0054A1 100%);
  --mix-color: linear-gradient(90deg, #C308AD 0%, #0B147D 100%);
  --title-color: rgba(48, 48, 48, 1)
}

body{
    font-family: var(--font-one);
    scroll-behavior: smooth !important;
    /* scrollbar-width: thin !important;  */
}
.logout{
  cursor: pointer;
}
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-container > *:nth-child(2) {
  flex: 1;
}
@media (max-width:426px) {
  #simple-404{
    margin-top: 35% !important; 
  }
}
#simple-404 {
  height: 100%;
  text-align: center;
  margin-top: 15%;
  /* margin-bottom: 15%; */
  /* display: grid !important; */
}

.container{
  width: 1400px;
  max-width: 100%;
}
.swiper-wrapper{
  margin-bottom: 10px;
  margin-top: 10px;
} 

@media (max-width:342px) {
  .nodata-img{
    width: 100%;
  }
}
.dropdown-menu .active{
  padding: 0 !important;
  padding-left: 10px !important;
}
.dropdown-menu .dropdown-item{
  margin-bottom: 4px !important;
}

.button2.active {
  background: red !important;
  color: white !important;
}

/*--------------------------------------------------------------
# Section 
--------------------------------------------------------------*/

section .title h2{
  font-size: 32px;
  font-family: var(--font-one);
  font-weight: 700;
  line-height: 43.58px;
  text-align: center;
  color: var(--title-color);
}


/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
 #header {
    height: 80px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    background: transparent !important;
    width: 100%;
  }

  /* #header.header-white-bg {
    background-color: white;
  } */

  #header.header-blurred {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    background-color: white !important;
}

  #header.header-transparent {
    background: transparent;
  }
  
  #header.header-scrolled {
    background: rgba(42, 44, 57, 0.9);
  }
  
  #header .logo h1 {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
  }
  
  #header .logo h1 a,
  #header .logo h1 a:hover {
    color: black;
    text-decoration: none;
  }
  #header .logo {
    margin-left: 35px;
  }
  #header .logo1 {
    margin-left: 35px;
  }
  @media (max-width:991px) {
    #header .logo1{
      display: none;
    }
    #header .logo{
      margin-left: 0;
    }
  }
  @media (max-width: 426px) {
    /* #header .logo {
      margin-left: 10px;
    } */
    #header .logo img{
      width: 185px !important;
    }
    #header .logo1 {
      margin-left: 10px;
    }
    #header .logo1 img{
      width: 185px !important;
    }
    
  }
  #header .logo img {
    padding: 0;
    margin: 0;
    /* margin-top: 10px; */
    width: 224px !important;
    height: 55.88px;
    object-fit: contain;
    margin-left: -60px;
  }
  #header .logo1 img {
    padding: 0;
    margin: 0;
    /* margin-top: 10px; */
    width: 224px;
    height: 55.88px;
    object-fit: contain;
  }
@media (max-width:1082px) {
  #header .logo img {
    width: 190px;
  }
  #header .logo1 img {
    width: 190px;
  }
}

   
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
 .navbar {
    padding: 0;
    max-width: 100%;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar a,
  .navbar a:focus {
    font-family: var(--font-one);
    font-weight: 400;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 5px 10px 5px 10px;
    /* margin-left: 5px; */
    font-size: 18px;
    color: var(--secondary-one);
    white-space: nowrap;
    transition: 0.3s;
    border-radius: 50px;
  }
  
  .navbar a i,
  .navbar a:focus i {
    font-weight: 500;
    font-size: 18px;
    line-height: 0;
    margin-left: 5px;
  }
  /* .navbar a:hover::before,
  .navbar .active::before,
  .navbar .active:focus::before,
  .navbar li:hover>a::before {
    content: '•'; 
    color: var(--main-blue);
    margin-right: 2px;
    font-size: 25px;
  } */

  .navbar .active::before {
    content: '•'; 
    color: var(--main-blue);
    margin-right: 2px;
    font-size: 25px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    background: none;
    color: var(--main-blue);
    font-weight: 500;
    font-size: 18px;
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    /* left: 5px; */
    top: calc(100% + 30px);
    margin: 5px 0 0 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 15px;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a {
    /* padding: 10px 20px; */
    color: #2a2c39;
    margin: 0 5px;
    font-size: 14px;
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: #fff;
  }
  
  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }

  .navbar li .button{
    border-radius: 25px;
    width: 190px;
    background: transparent;
    border: 1px solid var(--main-blue);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    font-size: 18px;
    font-family: var(--font-one);
    font-weight: 600;
    color: var(--main-blue);
  }
  .navbar li .button:hover{
    background: var(--main-blue);
    color: white;
  }
  .profile-icon-header li button{
    border-radius: 25px;
    width: 190px;
    background: transparent;
    border: 1px solid var(--main-blue);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    font-size: 18px;
    font-family: var(--font-one);
    font-weight: 600;
    color: var(--main-blue);
  }

  @media (max-width:991px) {
    .profile-icon-header{
      display: flex;
      align-items: center;
    }
    .profile-icon-header li button{
      margin-right: 10px;
      width: 130px;
      font-size: 12px;
    }
    .profile-icon-header .profile-dropdown{
      /* top: 100% !important; */
      margin-top: 10px;
      border-radius: 15px;
    }
  }
  .profile-icon-avtar img{
    border: 1px solid #455A64
  }
  @media (min-width: 991px) {
  
    .profile-icon-header{
      display: none;
    }
    .profile-icon-header li button {
      display: none;
    }
    .profile-icon-header .profile-icon-avtar{
      display: none;
    }
  }


  .profile-icon-header li button:hover{
    background: var(--main-blue);
    color: white;
  }

  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }


  .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: none !important;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
  .profile-icon-avtar{
  margin-right: 15px !important;
  }
  .profile-dropdown{
    right: 10px !important;
  }
 
  /* .profile-dropdown li:hover{
    background: gainsboro !important;
  } */
  .profile-dropdown li a:hover{
    color: var(--main-blue) !important;
    background: gainsboro !important;
  }
  .profile-dropdown li a .active{
    color: var(--main-blue) !important;
    background: gainsboro !important;
  }
  .dropdown-item.active{
    background-color:gainsboro !important ;
    color: var(--main-footer) !important;
  }
  .profile-icon-header li{
    list-style: none;
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: var(--main-blue);
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
    /* z-index: 9999 !important; */
  }

  /* .navbar {
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
  }
  
  .navbar.mobile-nav-open {
    transform: translateX(0);
  } */
  
  .logoline{
    color: white;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }
  .mobile-nav-open {
    height: 100vh !important;
    position: absolute !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: 0.3s;
    z-index: 999;
    background-color: #455A64 !important;
    width: 100% !important; 
  }
  
  .mobile-nav-open .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .mobile-nav-open ul {
    display: block;
    position: absolute;
    top: 55px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 0;
    overflow-y: auto;
    transition: 0.3s ease-in-out;
    background: #455A64;
    color: white;
    margin-bottom: auto;
  }
  
  .mobile-nav-open a,
  .mobile-nav-open a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: white;
    justify-content: flex-start !important;
    
  }
  
  .mobile-nav-open a:hover,
  .mobile-nav-open .active,
  .mobile-nav-open li:hover>a {
    color: white;
  }
  
  .mobile-nav-open .getstarted,
  .mobile-nav-open .getstarted:focus {
    margin: 15px;
  }
  
  .mobile-nav-open .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .mobile-nav-open .dropdown ul li {
    min-width: 200px;
  }
  
  .mobile-nav-open .dropdown ul a {
    padding: 10px 20px;
  }
  
  .mobile-nav-open .dropdown ul a i {
    font-size: 12px;
  }
  
  .mobile-nav-open .dropdown ul a:hover,
  .mobile-nav-open .dropdown ul .active:hover,
  .mobile-nav-open .dropdown ul li:hover>a {
    color: #fff;
  }
  
  .mobile-nav-open .dropdown>.dropdown-active {
    display: block;
  }
  .mobile-nav-open .active::before {
    content: '•'; 
    color: white;
    font-size: 25px;
  }

/*--------------------------------------------------------------
# Home
--------------------------------------------------------------*/

.bg-cover-img{
  background-image: url('./assets/img/bgimg.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 900px;
  max-width: 100%;
}

@media (max-width: 1091px) and (min-width: 995px) {
  .bg-cover-img {
    height: 1000px;
  }
}
@media (max-width: 426px){
  .bg-cover-img {
    height: 800px !important;
  }
  .hero h1{
    font-size: 30px;
    padding-left: 10px;
  }
  .hero p{
    font-size: 15px;
    padding-left: 10px;
  }
  .hero .btn-get-started{
    margin-top: 2rem !important;
    height: 60px !important;
    font-size: 18px;
    margin-left: 10px !important;
  }
  
}


/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 80vh;
  position: relative;
  padding: 120px 0 60px 0;
  display: flex;
  align-items: center;
  margin-top: -58px;
}
@media (max-width: 426px) {
  .hero {
    min-height: auto;
    margin-top: -38px;
  }
  
}

.hero .hero-title{
  /* margin-top: 5rem; */
  padding-left: 50px;
}
@media (max-width:1040px) {
  .hero .hero-title{
    margin-top: 2rem;
   
  }
}
.hero .hero-title-btn{
  padding-left: 37px !important;
}
@media (max-width: 426px) {
  .hero .hero-title{
    padding-left: 10px;
    margin-top: 1rem;
  }
  .hero .hero-title-btn{
    padding-left: 0px !important;
  }
  .hero .btn-get-started {
    width: 195px !important;
    font-size: 12px !important;
  }

}

.hero h1 {
  margin: 0;
  font-family: var(--font-one);
  font-size: 54px;
  font-weight: 700;
  line-height: 73px;
  background: var(--mix-color) ;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.hero p {
  margin: 5px 0 30px 0;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 400;
  color: #444444;
}

/* .hero .hero-card{
  width: 750px;
  height: 165px;
  margin-left: -240px;
  border-radius: 35px;
  overflow: hidden;
} */
.hero .hero-card .hero-card-img{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15rem;
  margin-top: 10px;
}


@media (max-width: 768px){
  /* .hero .hero-card{
    max-width: 100%;
    height: 140px;
    max-height: 100vh;
    margin-left: 0;
    border-radius: 35px;
  } */
  /* .hero .hero-card img{
    width: 150px;
    height: 150px;
    object-fit: contain;
    aspect-ratio: 1/3;
    margin-top: -14px;
  } */
  .hero .hero-card .hero-card-img{
    flex-direction: column;
    margin-left: 0;

  }
  .bg-cover-img{
    height: 900px;
  }
}

/* .hero .hero-card .hero-card-img img{
  width: 120px;
  object-fit: contain;
  aspect-ratio: 5/2;
  margin-inline: 30px;
  margin-top: 10px;
  margin-left: 10px;
} */


.hero .btn-get-started {
  font-family: var(--font-one);
  font-weight: 500;
  font-size: 25px;
  top: -787px;
  left: -277px;
  width: 301px;
  height: 70px;
  background: var(--mix-blue-color);
  padding: 10px 28px 12px 28px;
  border-radius: 50px;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;

}
.hero .btn-get-started:hover {
  box-shadow: 0px 5px 10px rgb(0, 0, 0, 0.5);
  background-color: rgba(0, 123, 255, 0.2);
}

/* .hero .hero-img .animated{
  margin-left: 50px;
} */

@media (max-width: 992px) {
  .custom-hide {
    display: none;
  }
}
@media (max-width: 1040px){
  .hero .hero-img .animated{
    width: 380px;
    margin-left: 100px;
  }
  .hero .btn-get-started{
    margin-left: 22px;
  }
}
/* .hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
} */

/* @keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
} */

.hero .student-enroll {
  display: flex;
  justify-content: start;
  margin-top: 5rem;
  margin-left: 25px;
  padding-left: 35px;
  /* padding-top: 25px; */
}
@media (max-width: 426px) {
  .hero .student-enroll {
    padding-left: 10px;
  }
  
}
.hero .student-enroll .text{
  color: var(--secondary-one);
  margin-left: 5px;
  font-family: var(--font-one);
  font-size: 20px;
}

.hero .avatar-group {
  display: flex; 
}

.hero .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: -15px; 
}

@media (max-width: 640px) {
  .hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .hero p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}
/*--------------------------------------------------------------width: 1075px;
height: 180px;
top: 945px;
left: 218px;
gap: 0px;
opacity: 0px;

# CompanyCard Home Section
--------------------------------------------------------------*/
.home-company-card{
  margin-top: 3rem;
}
.home-company-card .marquee-home .hero-card{
  width: 1075px;
  height: 180px;
  border-radius: 25px;
  border: 0;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
}
.home-company-card .marquee-home .hero-card img{
  height: 40px;
  width: 214px;
  object-fit: contain;
}


/*--------------------------------------------------------------
# Slills Section
--------------------------------------------------------------*/
.skills .toggle-button{
  width: 150px;
  height: 40px;
  border-radius: 20px;  
  background: transparent;
  color: var(--main-blue);
  border: 1px solid var(--main-blue);
}
@media (max-width: 426px) {
  .skills .swiper{
    padding-left: 40px;
  }
}
.skills .swiper-slide{
  width: 318px !important;
  height: 310px;
  align-items: center;
}

.skills{
  margin-top: 3rem;
}

.skills .skills-button{
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  gap: 10px;
  align-items: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.skills .skills-button h5{
  font-family: var(--font-one);
  font-size: 18px;
  font-weight: 400;
  line-height: 24.5px;
  margin-top: 10px !important;
}

.skills .skills-button .button{
  width: 170px;
  height: 50px;
  font-weight: 500;
  flex: 1 1 auto;
  font-size: 20px;
  font-family: var(--font-one);
  border-radius: 60px;
  background-color: #F6F6F6;
  color: var(--secondary-one);
  border: 0;
  line-height: 27.24px;
  transition: 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .skills .skills-button {
    flex-direction: column;
  }

  .skills .skills-button .button {
    width: 100%;
    margin-bottom: 10px;
  }
}

.skills .skills-button .button:hover,
.skills .skills-button .button.active {
  background-color: var(--main-blue);
  color: white;
}



.skills-card {
  margin-bottom: 2rem;
  margin-top: 3rem !important;
  justify-content: space-evenly;
}

.skills .skills-col-hero{
  width: 318px !important;
}

.skills-card .card{
  width: 318px;
  height: 310px;
  border-radius: 10px;
  background: white;
  border: 0;
  box-shadow: 0px 0px 3px rgb(0, 0, 0, 0.2);
  transition: 0.2s ease-in-out;
  margin-bottom: 10px;
}

.skills-card .card:hover{
  box-shadow: 0px 3px 5px rgb(0, 0, 0, 0.3);
}

.skills-card .card .avatar {
  width: 160px;
  height: 60px;
  border-radius: 10px;
  object-fit: cover; 
  margin-left: 10px;
  margin-top: 5px;
  border: 0px solid #ECECEC;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white !important;

}
.skills-card .card .avatar img{
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
  /* aspect-ratio: 3/2; */
}



.skills-card .card .company-description{
  margin-left: 20px;
  margin-top: 12px;
}

.skills-card .card .company-description h4{
  font-family: var(--font-one);
  font-size: 18px;
  font-weight: 700;
  line-height: 24.51px;
}

.skills-card .card .company-description h6{
  font-family: var(--font-one);
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  color: var(--secondary-one);
}
.skills-card .card .company-description .company-destination{
  display: flex;
  justify-content: start;
  align-items: start;
  margin-bottom: 5px;
}
.skills-card .card .company-description .company-destination p{
  margin-left: 5px;
  color: var(--secondary-one);
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-one);
  line-height: 19.07px;
}

.skills-card .card .company-description .primary-skills{
  display: flex;
  justify-content: start;
  align-items: start;
  /* margin-left: 5px; */
}
.skills-card .card .company-description .primary-skills .skill-text-main{
  font-weight: 600 !important;
}

.skills-card .card .company-description .primary-skills p{
  margin-left: 5px;
  font-family: var(--font-one);
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 16.34px;
  color: var(--secondary-one);
  margin-right: 12px;
}

.skills-card .card .company-description .primary-skills span{
  text-wrap: wrap;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16.34px;
}

.company-description .icon{
  color: var(--secondary-one);
  font-size: 18px;
}

.skills-card .btn-div{
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 10px;
}

.skills-card .btn-div .button{
  width: 272px;
  height: 40px;
  border-radius: 40px;
  border: 0;
  background-color: var(--main-footer);
  color: white;
  font-family: var(--font-one);
  font-weight: 500;
  font-size: 16px;
  list-style: 21.79px;
}



/*--------------------------------------------------------------
# Course Section
--------------------------------------------------------------*/
.courses .swiper{
  padding-top: 120px;
}

.courses .swiper-slide{
  width: 360px !important;
}

.courses{
  background: linear-gradient(180deg, #F6F7FF 30.1%, #FFFFFF 100%);
}

.courses .title{
  /* margin-top: 5rem; */
  padding-top: 3rem;
}
.courses .courses-card{
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}
/* .courses .course-code-row{
  margin-top: 2rem;
} */
.courses .course-code{
  margin-top: 25px;
}

.courses .course-code .code-title{
  display: flex;
  justify-content: center;
}

.courses .course-code .code-title .code-text{
  background: linear-gradient(90deg, #121380 0%, #C008AC 100%);
  width: 270px;
  height: 62px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.courses .course-code .code-title .code-text img{
  width: 40px;
  height: 40px;
}
.courses .course-code .code-title .code-text h6{
  color: white;
  font-family: var(--font-two);
  font-size: 28px;
  font-weight: 400;
  line-height: 38.02px;
  flex-wrap: wrap;
}
.courses .course-apti{
  margin-top: 50px;
}
.courses .course-apti .apti-title{
  display: flex;
  justify-content: center;
}

.courses .course-apti .apti-title .apti-text{
  background: linear-gradient(90deg, #121380 0%, #C008AC 100%);
  width: 300px;
  height: 62px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.courses .course-apti .apti-title .apti-text img{
  width: 40px;
  height: 40px;
}
.courses .course-apti .apti-title .apti-text h6{
  color: white;
  font-family: var(--font-two);
  font-size: 28px;
  font-weight: 400;
  line-height: 38.02px;
}
.course-code .course-code-row{
  justify-content: center;
  margin-top: 3rem;
}
.course-code .course-code-col{
  /* width: 318px !important;  */
  display: flex;
  justify-content: center;
}
.course-code .card{
  width: 318px;
  height: 310px;
  border-radius: 30px;
  border: 0;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 4rem;
}
.course-code .card .avatar{
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background: rgba(1, 180, 137, 1);
  position: absolute;
  top: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.course-code .card .avtar-1{
  background: rgba(1, 177, 180, 1);

}
.course-code .card .avtar-2{
  background: rgba(1, 94, 180, 1);

}
.course-code .card .avtar-3{
  background: rgba(155, 1, 180, 1);
}
.course-code .card .avtar-4{
  background: rgba(180, 1, 119, 1);
}
.course-code .card .avatar .icon{
  color: white;
  font-size: 2.5em;
}

.course-code .card .description{
  margin-top: 50px;
}
.course-code .card .description h5{
  font-family: var(--font-one);
  font-size: 20px;
  font-weight: 600;
  line-height: 27.24px;
  text-align: center;  
  flex-wrap: wrap;
}

.course-code .card .description h6{
  font-family: var(--font-one);
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: center;
  flex-wrap: wrap;
}
.course-code .card .desc-sect{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.course-code .card .desc-sect .btn-desc{
  width: 62px;
  height: 22.07px;
  border-radius: 30px; 
  font-family: var(--font-one);
  font-size: 10px;
  font-weight: 500;
  line-height: 13.62px;
  color: rgba(48, 48, 48, 1);
  text-align: start;
  background: rgba(248, 248, 248, 1);
  border: 0;
  cursor: text;
  padding: 0;
  text-align: center;
}
.course-code .card .description-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 12px;
}
.course-code .card .description-btn .btn-more{
  width: 284px;
  height: 42.13px;
  border-radius: 40px;
  background: rgba(242, 243, 255, 1);
  color: var(--main-blue);
  border: 0;
}

.course-code .card .description-btn .btn-now{
  width: 284px;
  height: 42.13px;
  border-radius: 40px;
  background: var(--main-blue);
  color: white;
  border: 0;
}






/* .courses .courses-card .card-1{
  width: 378px;
  height: 212px;
  border-radius: 30px;
  margin-left: 20px;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
  margin-top: -7rem;
  border: 0;
} */

/* 
.courses .courses-card .card-1 img{
  width: 378px;
  height: 212px;
  border-radius: 30px;
  object-fit: cover;
}
.courses .courses-card .card-2{
  width: 424px;
  height: 238px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.1);
  border: 0;
}
.courses .courses-card .card-2 .description{
  margin-top: 7rem;
}
.courses .courses-card .card-2 .description h5{
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 27.24px;
  color: #303030;
}
.courses .courses-card .card-2 .description .time{
  display: flex;
  justify-content: center;
  align-items: center;
}
.courses .courses-card .card-2 .description .time .time-2{
  display: flex;
  justify-content: center;
 
}
.courses .courses-card .card-2 .description .time .time-2 p{
  font-weight: 500;
  font-size: 12px;
  font-family: var(--font-one);
  color: #7F7F7F;
}
.courses .courses-card .card-2 .description  .time .rating p{
  font-weight: 500;
  font-size: 12px;
  font-family: var(--font-one);
  color: #7F7F7F;
}
.courses .courses-card .card-2 .description  .time .rating .star{
  color: yellow;
}
.courses .courses-card .card-2 .description  .time .rating{
  display: flex;
  justify-content: center;
}
.courses .courses-btn {
  display: flex;
  justify-content: center;
}
.courses .courses-btn .button{
  width: 390px;
  height: 40px;
  border-radius: 40px;
  border: 0;
  background-color: var(--secondary-two);
  color: var(--main-blue);
} */

/* @media (max-width: 426px) {
  .courses .courses-card .card-1{
    width: 350px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .courses .courses-card .card-1 img{
    width: 340px;
    height: 160px;
    border-radius: 30px;
    
  }
  .courses .courses-card .card-2{
    width: 380px;
  }
  .courses .courses-btn .button{
    width: 320px;
  }
} */

/*--------------------------------------------------------------
# Work Section
--------------------------------------------------------------*/

.work{
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.work .work-bg{
  background: rgba(120, 130, 173, 0.1);
  margin-top: 15px;
}

@media (max-width: 426px) {
  .work .work-bg .work-div{
    justify-content: center !important;
  }
}

.work .work-bg .work-div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width:547px) {
  .work .work-bg .work-div{
    justify-content: center !important;
  }
}

.work .work-bg .work-div .work-div2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.work .work-bg .work-div .work-div2 img{
  width: 261px;
  height: 261px;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.work .work-bg .work-div .work-div2 .work-div3{
  width: 262px;
  height: 87px;
}

.work .work-bg .work-div .work-div2 .work-div3 h6{
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  font-family: var(--font-one);
  line-height: 27.24px;
  color: rgba(125, 125, 125, 1);
}

.work .work-bg .work-div .work-div2 .work-div3 p{
  text-align: center;
  text-wrap: wrap;
  font-weight: 500;
  font-size: 16px;
  font-family: var(--font-one); 
  line-height: 21.79px;
  color: rgba(48, 48, 48, 1);
  white-space: normal;
  
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/

/* Testimonials.css */
.testimonials .swiper-button-next,
.swiper-button-prev {
  color: var(--main-blue) !important;
}

.testimonials.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: var(--main-blue) !important;
}

.testimonials{
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.testimonial-card {
  border-radius: 20px;
  position: relative;
  margin-top: 8rem;
  width: 424;
  height: 238px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  background: rgba(255, 255, 255, 1);

}

.testimonial-image-wrapper {
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);
  width: 116px;
  height: 116px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-image {
  width: 100%;
  height: 100%;
}

.card-body {
  padding: 0 !important;
  padding-top: 30px !important;
}

.card-text {
  font-family: var(--font-one);
  font-weight: 400;
  font-size: 14px;
  color: rgba(96, 96, 96, 1);
  list-style: 19.7px;
}

.card-title {
  font-family: var(--font-one);
  color: rgba(48, 48, 48, 1);
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
}

.card-subtitle {
  font-family: var(--font-one);
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: rgba(96, 96, 96, 1);
}
@media (max-width: 399px){
  .testimonial-card {
    height: 310px;
  }
}

@media (max-width: 1180px) and (min-width: 760px) {
  .testimonial-card {
    /* height: 400px; */
    max-height: 100vh;
  }
}


/*--------------------------------------------------------------
# About Job Section
--------------------------------------------------------------*/

.get-test-app img{
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.aboutjob{
  margin-top: 8rem;
}
.aboutjob .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aboutjob .title .button{
  width: 46px;
  height: 46px;
  background: rgba(255, 255, 255, 1);
  color: black;
  font-size: 1.2em;
  border-radius: 50%;
  border: 0;
  margin-left: auto;
  box-shadow: 0px 0px 2px rgb(0, 0, 0, 0.2);
}

.aboutjob .title h2{
  font-size: 32px !important;
  font-family: var(--font-one);
  font-weight: 600;
  line-height: 43.58px;
  color: var(--title-color);
  flex-grow: 1;
  text-align: center;
}

@media (max-width: 426px) {
  .aboutjob .title h2{
    font-size: 22px !important;
  }
}

.aboutjob {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;
}

.aboutjob .card1{
  width: 100%;
  /* height: 260px; */
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  border: 0;
  border-radius: 0;
  /* background:rgba(237, 237, 255, 1) ; */
  background: var(--main-footer);
  margin-bottom: 2rem;
  margin-top: 3rem;
}
.aboutjob  .card1 .container{
  padding-top: 30px;
}
.aboutjob  .card1 .avatar {
  width: 200px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover; 
  margin-left: 40px;
  margin-top: 20px;
  border: 1px solid #ECECEC;
  background: white;
}
.aboutjob  .card1 .avatar img{
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
}

.aboutjob .about-description {
  margin: 50px;
  margin-top: 20px;
}
.aboutjob .about-description-text-sub{
  color: white;
}
.aboutjob .about-description .info-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
@media (max-width: 392px) {
  .aboutjob .about-description .info-btn{
    flex-direction: column;
  }
}
.aboutjob .about-description .info-btn .icon{
  font-size: 1.5em;
  margin-top: -2px;
}

.aboutjob .about-description .info-btn .button1{
  width: 130px;
  height: 40px;
  border-radius: 60px;
  margin-bottom: 10px;
  background: transparent;
  border: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.07px;
  color: var(--main-blue);
}
.aboutjob .about-description .info-btn .button2{
  width: 130px;
  height: 40px;
  border-radius: 60px;  
  font-weight: 700;
  font-size: 14px;
  line-height: 19.07px;
  border: 0;
  color: var(--main-footer);
  background-color: white;
  margin-right: 10px;
}

.aboutjob  .card2{
  max-width: 100%;
  border-radius: 5px;
  padding: 15px;
  border: 0;
  box-shadow: 0px 1px 4px 0px #00000040;
}
.aboutjob  .card3{
  max-width: 100%;
  border-radius: 5px;
  padding: 15px;
  border: 0;
  box-shadow: 0px 1px 4px 0px #00000040;
  margin-top: 2rem;
}

.aboutjob .card2 h4{
  font-size: 20px;
  font-family: 600;
  font-family: var(--font-one);
  line-height: 27.24px;
  color: rgba(48, 48, 48, 1);
}
.aboutjob .card2 p{
  font-size: 16px;
  font-family: 400 !important;
  font-family: var(--font-one);
  line-height: 21.79px;
  color: rgba(96, 96, 96, 1);
  text-wrap: wrap;
  margin-bottom: 0;
}


@media (max-width: 450px){
  .aboutjob .about-description{
    flex-direction: column;
  }

  .aboutjob .about-description .info-btn .button1{
    margin-top: 30px;
  }

}

.aboutjob .skills-card{
  margin-top: 2rem !important;
}

.aboutjob .skills-card .tech-card{
  padding: 20px;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  border: 0;
  border-radius: 5px;
  /* background: linear-gradient(90deg, #AFFCE8 0%, #5DD0D2 100%); */
  box-shadow: 0px 1px 4px 0px #00000040;
}
.aboutjob .skills-card .button{
  background: var(--main-footer);
  border: 0;
}
.aboutjob .skills-card .soft-card{
  padding: 20px;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  border: 0;
  border-radius: 5px;
  /* background: linear-gradient(90deg, #AFFCE8 0%, #5DD0D2 100%); */
  box-shadow: 0px 1px 4px 0px #00000040;

}


.aboutjob .card2 .skills-card .button{
  border-radius: 30px;
  background: rgba(175, 252, 201, 1);
  border: 1px solid rgba(255, 255, 255, 1);
  font-family: var(--font-one);
  font-weight: 400;
  font-size: 16px;
  line-height: 21.79px;
  color: rgba(48, 48, 48, 1);
}

.aboutjob .skills-card .button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; 
  margin-top: 5px;
}

.aboutjob  .get-tested{
  margin-top: 5rem;
  margin-bottom: 5rem;
  border-radius: 20px;
  background: rgba(237, 237, 255, 1);
  max-width: 100%;
  width: 1014px;
  height: 316px;
  top: 1345px;
  left: 250px;
  gap: 0px;
  /* opacity: 0px; */
}

.aboutjob  .get-tested .swiper{
  padding-top: 100px;
  margin-top: -109px;
}

.aboutjob .get-tested .swiper .swiper-wrapper{
  padding-left: 20px;
}

.aboutjob .get-tested h4{
  margin: 30px;
}

.aboutjob  .course-reco{
  margin-bottom: 5rem;
  border-radius: 20px;
  background: rgba(171, 229, 247, 1);
}

.aboutjob  .get-tested .get-tested-card{
  margin-top: 6rem;
  padding: 20px;
}

.aboutjob  .get-tested .card-test1{
  background: linear-gradient(180deg, #FF8FE0 0%, #995686 100%);
  width: 202px;
  height: 142px;
  border-radius: 20px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  margin-top: -6rem;
  margin-left: 25px;
}
.aboutjob  .get-tested .card-test1 img{
  width: 188px;
  height: 130px;
  border-radius: 20px;
}

.aboutjob  .get-tested .card-test2{
  width: 250px;
  height: 117px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
}
.aboutjob  .get-tested .card-test2 h5{
 margin-top: 60px;
 text-align: center;
}

.course-reco .swiper{
  padding-top: 100px;
  padding-left: 30px;
}
.course-reco .swiper .swiper-wrapper{
  padding-left: 40px;
}
@media (min-width:377px) {
  .course-reco .swiper .swiper-wrapper{
    padding-left: 10px;
  }
}

.course-reco .swiper-slide{
  width: 270px !important;
}

.course-reco {
  max-width: 100%;
  width: 1014px;
  height: 390px;
}

.course-reco .courses-card{
  margin-top: 3rem;
}

.course-reco h4{
  font-family: var(--font-one);
  font-size: 20px;
  font-weight: 600;
  line-height: 27.24px;
  margin-left: 50px;
  margin-top: 20px;  
}

.course-reco .courses-card .card-1{
  width: 241.28px;
  height: 135.32px;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.1);
  margin-left: 15px;
  position: absolute;
  z-index: 1;
  margin-top: -6rem;
  border: 0;
  padding: 5px;
}
.course-reco .courses-card .card-1 img{
  width: 229.79px;
  height: 122.5px;
  border-radius: 20px;
}

.course-reco .courses-card .card-2{
  width: 270px;
  height: 160px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.1);
  border: 0;
}

.course-reco .courses-card .card-2 .description{
  margin-top: 2rem;
}
.course-reco .courses-card .card-2 .description h5{
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 27.24px;
  color: #303030;
}
.course-reco .courses-card .card-2 .description .time{
  display: flex;
  justify-content: center;
  align-items: center;
}
.course-reco .courses-card .card-2 .description .time .time-2{
  display: flex;
  justify-content: center;
 
}
.course-reco .courses-card .card-2 .description .time .time-2 p{
  font-weight: 500;
  font-size: 12px;
  font-family: var(--font-one);
  color: #7F7F7F;
}
.course-reco .courses-card .card-2 .description  .time .rating p{
  font-weight: 500;
  font-size: 12px;
  font-family: var(--font-one);
  color: #7F7F7F;
}
.course-reco .courses-card .card-2 .description  .time .rating .star{
  color: yellow;
}
.course-reco .courses-card .card-2 .description  .time .rating{
  display: flex;
  justify-content: center;
}
.course-reco .courses-btn {
  display: flex;
  justify-content: center;
}
.course-reco .courses-btn .button{
  width: 390px;
  height: 40px;
  border-radius: 40px;
  border: 0;
  background-color: var(--secondary-two);
  color: var(--main-blue);
}





/*--------------------------------------------------------------
# Footer Section
--------------------------------------------------------------*/

.footer{
  background-color: var(--main-footer);
  display: flex;
  max-height: 100%;
  /* height: 254px; */
  margin-top: 0;
}

.footer .list-unstyled{
  display: flex;
  justify-content: center;
}
.footer .list-unstyled li {
  margin: 10px;
  margin-top: 0px;
}
.footer .list-unstyled li a{
  text-decoration: none !important;
  font-family: var(--font-one);
  font-size: 14px;
  font-family: 400;
  line-height: 19.07px;
  color: rgba(236, 236, 236, 1) !important;
}
.footer .list-unstyled li a:hover{
  text-decoration: underline;
}

.footer .contact-us{
  font-family: var(--font-one);
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  padding-right: 35px;

}

.footer .contact-us-list{
  padding-left: 0px !important;
}
.contact-us-title{
  padding-left: 5px;
}

.footer .contact-us .contact-us-list li{
  text-decoration: none !important;
  list-style: none;
  /* margin-bottom: 5px; */
  display: flex; 
  justify-content: flex-start;
}

.footer-year-main{
  margin-top: -100px;
 
}
.footer-logo-main{
  margin-left: 10px;
}
@media (max-width:990px) {
  .footer-year-main{
    margin-top: 0px;
  }
  .footer .contact-us-sub{
    padding-left: 25px;
  }
  .footer .list-unstyled{
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
   padding-left: 25px;
  }
  .footer-logo-main{
    margin-top: 0px !important;
  }
}
.footer .footer-year{
  font-family: var(--font-one);
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  color: rgba(189, 189, 189, 1);
  margin-top: 13px;
  opacity: 50%;
}

/* @media (max-width:768px) {
  .footer{
    margin-top: 10rem;
  }
} */

