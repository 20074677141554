@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

#CourceHero {
  position: relative;
  background: linear-gradient(
      rgba(45, 47, 128, 1),
      rgba(113, 88, 181, 1)
    ),
    url("./img/rm380-11 1.png");
  background-size: cover;
  max-height: 100%;
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  margin-top: 5rem !important;
  padding-top: 10rem;
}
@media (max-width:1246px){
  #CourceHero{
    height: 100%;
    padding-top: 0 !important;
  }
  .hdl-cc{
    margin-top: 5px !important;
  }
}


.left-section, .right-section {
  position: relative;
  z-index: 1;
}

.hero-img1 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.btn-custom-cc {
  background-color: white;
  border-radius: 30px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 27.24px;
  color: #2D2F80;
  border: 1px solid #2D2F80;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15) !important;
}

.btn-custom-cc:hover {
  background-color: #f8f9fa;
  color: #2D2F80;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}

.hdl-cc {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 45px;
  line-height: 60.54px;
  background: linear-gradient(to right, #FC568B 10%, #FE7C61 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-top: 50px;
}

.sub-cc {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32.68px;
  color: #FFFFFF;
}

.about-hero {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
}

.left-section, .right-section {
  padding: 0;
  margin: 0;
}

.row.no-gutters {
  margin: 0;
}

.col-sm-12, .col-md-6 {
  padding: 0;
}

@media (max-width: 1200px) {
  .hdl-cc {
    font-size: 48px;
    line-height: 64px;
  }

  .sub-cc {
    font-size: 20px;
    line-height: 27px;
  }

  .btn-custom-cc {
    font-size: 18px;
    max-width: 250px;
  }
}

@media (max-width: 992px) {
  .hdl-cc {
    font-size: 42px;
    line-height: 56px;
  }

  .sub-cc {
    font-size: 18px;
    line-height: 24px;
  }

  .btn-custom-cc {
    font-size: 16px;
    max-width: 200px;
  }
}

@media (max-width: 768px) {
  .hdl-cc {
    font-size: 36px;
    line-height: 48px;
  }

  .sub-cc {
    font-size: 16px;
    line-height: 22px;
  }

  .left-section {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .btn-custom-cc {
    font-size: 14px;
    max-width: 180px;
  }
}

@media (max-width: 576px) {
  .hdl-cc {
    font-size: 30px;
    line-height: 40px;
  }

  .sub-cc {
    font-size: 14px;
    line-height: 20px;
  }

  .btn-custom-cc {
    font-size: 12px;
    max-width: 250px;
  }
}
