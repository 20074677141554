
.curriculum{
    padding-top: 30px;
    margin-top: 30px;
    background: rgba(245, 237, 255, 1);
    padding-bottom: 30px;
}

.curriculum .title{
    padding-bottom: 30px;
}
.curriculum .accordion{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    --bs-accordion-bg: none;
}

.accordion-button:focus {
    z-index: 0 !important;
    outline: 0 !important;
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: black !important;
    background-color: white !important;
    box-shadow: none !important;
}

.curriculum .accordion-item{
    margin-bottom: 20px;
    border-radius: 20px !important;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);
    max-width: 100%;
    width: 1014px;
    border: 0;
}

.curriculum .accordion-item h2{
    font-family: Open Sans;
    font-size: 22px !important;
    font-weight: 600;
    line-height: 29.96px;
}

.curriculum .accordion-item span{
    margin-top: -10px;
    margin-left: 10px;
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
    color: rgba(96, 96, 96, 1);
}
.curriculum .sub-title{
    display: flex;
    justify-content: center;
    margin-right: 25rem;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    color: rgba(96, 96, 96, 1);
}


