@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

#tch-main{
  position: relative;
  justify-content: center;
  /* margin-top: 5rem; */
}
#details-tch-ab{
  display: flex;
  position: absolute;
  top: -50px;
  justify-content: center;
  width: 100%;
}
.hdl-tc {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.hdl-tcc {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 32px;
  line-height: 43.58px;
  color: #1e1e1e;
}

.logo-tc {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 900px;
  height: 100px;
  margin: 0 auto;
}

.logo-tc .scrolling-logos {
  display: flex;
  gap: 10px;
  align-items: center;
  animation: scroll 15s linear infinite;
  white-space: nowrap;
}

.logo-tc img {
  width: 29%;
  height: 50%;
  margin-right: 20px;
}

.outer-tch {
  border: 1px solid #99c7ff;
  border-radius: 30px;
  background-color: #ebf4ff;
  width: 800px;
  margin: 0 auto;
}
.outer-tch-ab {
  background-color: #ebdbff;
  width: 800px;
  margin: 0 auto;
  border-radius: 30px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
}
.heading {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 21.79px;
  color: #303030;
}

.paragraph {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 21.79px;
  color: #303030;
}

.inner-box {
  border-right: 1px solid #99c7ff;
}
.bg-tch-ab {
  background-color: white;
  border-radius: 100px;
}
.bg-tch-ab img {
  justify-content: center;
}

.tch-hdl {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 46.3px;
  color: #2d2f80;
  text-align: left;
}

.tch-shl {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 21.79px;
  color: #606060;
  text-align: left;
}

#details-tch {
  display: flex;
  justify-content: center;
}

#details-tch .d-flex {
  display: flex;
  justify-content: center;
}

@keyframes scroll {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@media (max-width: 1200px) {
  .logo-tc {
    height: 80px;
  }

  .logo-tc img {
    width: 12%;
  }

  .outer-tch {
    width: 80%;
  }
  .outer-tch-ab {
    width: 80%;
  }
}

@media (max-width: 992px) {
  .logo-tc {
    height: 60px;
  }

  .logo-tc img {
    width: 10%;
  }

  .outer-tch {
    width: 90%;
  }
  .outer-tch-ab {
    width: 90%;
  }

  .inner-box {
    border-right: none; 
    border-bottom: 1px solid #99c7ff; 
  }
}

@media (max-width: 768px) {
  .logo-tc {
    height: 50px;
  }

  .logo-tc img {
    width: 80%;
  }

  .outer-tch {
    width: 95%;
  }
  .outer-tch-ab {
    width: 95%;
  }
  /* .hdl-tc{
    margin-top: 120px;
  } */
}
