#let_connect {
    background-image: url('./img/Group 217.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
    position: relative;
}

.hdl-lc {
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 45px;
    line-height: 61.28px;
}

.left-section img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    position: absolute;
    bottom: 10px;
}

#let_connect .btn-custom {
    background-color: white; 
    border-radius: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 27.24px;
    color: #2D2F80;
    border: 1px solid #2D2F80;
}
@media (max-width: 425px) {
    #let_connect .btn-custom{
        padding: 10px !important;
    }
}

#let_connect .btn-custom:hover {
    background-color: #f8f9fa;
    color: #2D2F80;
}

@media only screen and (max-width: 600px) {
    .left-section img {
        position: static;
        margin-top: 20px; /* Adjust as needed to create space between sections */
    }
}
@media (max-width: 768px) {
    .hide-on-mobile {
      display: none !important;
    }
  }
